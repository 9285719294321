import { useEffect } from "react";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import "../css/NotFound.css";

const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Cookies.set("notfound", true, { path: '/' }); // Nastavení cookie v useEffect
  }, []);

  return (
    <div className='not-found-container'>
      <h1>{t('notFound.title')}</h1>
      <p>{t('notFound.message')}</p>
    </div>
  );
};

export default NotFound;
