import React, { useEffect, useContext, useState, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import useUserDetails from '../hooks/useUserDetails';

const ShopOption = () => {
  const navigate = useNavigate();
  const { lng } = useParams();

  const {
    checkLoginStatus,
    userData,
    uuid,
    setUuid,
    appContentType,
    selectedShop,
    setSelectedShop,
    isAdmin,
    setIsAdmin,
    setIsUserDataLoading
  } = useContext(AppContext);

  const [prevUuid, setPrevUuid] = useState(uuid); // Uložíme předchozí 

  useLayoutEffect(() => {
    setIsUserDataLoading(true)
  }, [])

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setUuid(userData.shops[0].uuid);
      sessionStorage.setItem("x23uU09I098D", userData.shops[0].uuid);

      setSelectedShop(userData?.shops[0])

      // userData.email === "info@permoniq.com" && setIsAdmin(true)
    }
  }, [userData]);

  useEffect(() => {
    if (prevUuid !== uuid) {
      setIsUserDataLoading(true);

      const timeout = setTimeout(() => {
        setIsUserDataLoading(false);
        setPrevUuid(uuid); // Aktualizujeme prevUuid, když se uuid změní
      }, 500); // simulujeme malé zpoždění, můžeš to upravit podle potřeby

      return () => clearTimeout(timeout); // čištění časovače při unmountu nebo nové změně uuid
    }
  }, [uuid, prevUuid, setIsUserDataLoading]);


  useUserDetails();

  const handleShopSelect = (selectedShopUuid) => {
    setUuid(selectedShopUuid);
    setSelectedShop(userData?.shops.find(oneShop => oneShop.uuid === selectedShopUuid))
    sessionStorage.setItem("x23uU09I098D", selectedShopUuid);
  };

  const handleSubmit = () => {

    if (selectedShop.status_create === "end") {
      window.scrollTo(0, 0)
      navigate(`/${lng}/app/${appContentType}/`);
    } else {
      window.scrollTo(0, 0)
      navigate(`/${lng}/app/register-shop/`);
    }
  };

  const handleNew = () => {
    sessionStorage.removeItem("x23uU09I098D")
    setSelectedShop("")
    setUuid("")
    window.scrollTo(0, 0)
    navigate(`/${lng}/app/register-shop/`);
  }

  const removeProtocol = (url) => {
    return url.replace(/^https?:\/\/(www\.)?/, ''); // Odstraní "http://", "https://" a "www."
  };

  return (
    <section className="section choose-shop">
      <article className="container" style={{ maxWidth: "50%" }}>
        <form className="form" autoComplete='off' onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <div className="box">
            <div className="select-container" style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}>
              <h2>Vyberte obchod</h2>
              <ol className="shop-list" style={{ width: "90%", listStyleType: 'none', padding: 0, maxHeight: '300px', overflowY: 'auto' }}>
                {
                  Object.keys(userData).length > 0 &&
                  userData.shops.sort((a, b) => removeProtocol(a.website).localeCompare(removeProtocol(b.website))).map((oneShop, index) => {
                    let statusText;
                    let statusBackgroundColor;
                    let statusTextColor;

                    if (oneShop.status_create === "end") {
                      statusText = Object.keys(oneShop.campaign).length > 0 ? "Aktivní" : "Neaktivní";
                      statusBackgroundColor = Object.keys(oneShop.campaign).length > 0 ? '#22E06C' : '#FFC4C4';
                      statusTextColor = Object.keys(oneShop.campaign).length > 0 ? "black" : '555';
                    } else {
                      statusText = "Registrace";
                      statusBackgroundColor = '#EFEFEF';
                      statusTextColor = "#000";
                    }

                    return (
                      <li
                        key={oneShop.uuid + index}
                        onClick={() => handleShopSelect(oneShop.uuid)}
                        style={{
                          cursor: 'pointer',
                          borderRadius: '4px',
                          margin: '5px 0',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <div className="item" style={{
                          display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: "center", backgroundColor: uuid === oneShop.uuid ? '#e0e0e0' : 'transparent', margin: 0, padding: 0,
                        }}>
                          <span className="item__ico">
                            <img src="/img/login-google.svg" alt="" />
                          </span>
                          <span className="item__title" style={{ flexGrow: 1, padding: 0, margin: 0 }}>
                            {removeProtocol(oneShop.website)}
                          </span>
                          <span className="item__control" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", margin: "0 !important", padding: 0 }}>
                            <label className="switcher" style={{ display: 'flex', alignItems: 'center', margin: "5px 10px", width: "120px" }}>
                              <span className="switcher__label" style={{
                                backgroundColor: statusBackgroundColor,
                                color: statusTextColor,
                                padding: '5px 10px',
                                borderRadius: '4px',
                                textAlign: 'center',
                                width: '100%',
                                borderRadius: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}>
                                {statusText}
                              </span>
                            </label>
                          </span>
                        </div>
                      </li>
                    );
                  })
                }
              </ol>
            </div>
          </div>
          {
            isAdmin ?
              <div className="btn__wrapper">
                <div className="register__submit">
                  <p>
                    <button className="btn" onClick={() => handleNew()}>Nový</button>
                  </p>
                </div>
                <div className="register__submit">
                  <p>
                    <button className="btn" type="submit">Vybrat</button>
                  </p>
                </div>
              </div>
              :
              <div className="register__submit">
                <p>
                  <button className="btn" type="submit">Vybrat</button>
                </p>
              </div>
          }
        </form>
      </article>
    </section>
  );
};

export default ShopOption;
