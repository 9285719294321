import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { AppContext } from '../context/AppContext';

import SimpleAlert from '../components/SimpleAlert';

import icons from '../img/icons.svg';
import { shallowEqual } from 'react-redux';

const NewPassword = () => {
    const { t } = useTranslation();
    const { lng } = useParams()
    const navigate = useNavigate();
    const location = useLocation()

    const {
        showAlert,
        closeAlert,
        isAlertVisible,
        setIsAlertVisible,
        alertMessage,
    } = useContext(AppContext)

    const [userId, setUserId] = useState("")
    const [token, setToken] = useState("")

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password2Visible, setPassword2Visible] = useState(false);

    const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
    const [isPasswordLettersValid, setIsPasswordLettersValid] = useState(false);
    const [isPasswordNumberValid, setIsPasswordNumberValid] = useState(false);
    const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);

    useEffect(() => {
        if (location.search) {
            const queryParams = new URLSearchParams(location.search)
            setUserId(queryParams.get("user_id"))
            setToken(queryParams.get("token"))
        }
    }, [])

    useEffect(() => {
        setIsPasswordLengthValid(password.length >= 8);
        setIsPasswordLettersValid(/[a-z]/.test(password) && /[A-Z]/.test(password));
        setIsPasswordNumberValid(/\d/.test(password));
        setDoPasswordsMatch(password !== '' && password2 !== '' && password === password2);
    }, [password, password2]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const togglePassword2Visibility = () => {
        setPassword2Visible(!password2Visible);
    };

    const onNewPasswordCreate = async (e) => {
        e.preventDefault();


        if (isPasswordLengthValid && isPasswordLettersValid && isPasswordNumberValid && doPasswordsMatch && userId && token) {
            try {
                const onNewPasswordCreateResponse = fetch(`https://permoniq.com/api/password-reset-confirm/${userId}/${token}/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body:
                        JSON.stringify({ new_password: password })

                })

                navigate(`/${lng}/login/`, { replace: true })
            } catch (error) {
                showAlert(t("newPassword.errorMessages.newPassErrorMsg"), "danger")
            }
        } else {
            console.log('Heslo nesplňuje požadavky');
            showAlert(t("newPassword.errorMessages.noSupportedPassErrorMsg"), "danger")
        }
    };

    return (
        <>
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    type={alertMessage.type}
                    onClose={closeAlert} />
            )}
            <section className="section login">
                <article className="container">
                    <h1 className="title">{t('newPassword.title')}</h1>
                    <form autoComplete="off" className="form" onSubmit={onNewPasswordCreate}>
                        <div className="box">
                            <p className="form__password">
                                <label htmlFor="password">{t('newPassword.newPasswordLabel')}</label>
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    autoComplete="new-password"
                                />
                                <span className="icon icon--eye" onClick={togglePasswordVisibility}>
                                    <svg className="icon__svg">
                                        <use xlinkHref={`${icons}#eye`} x="0" y="0" width="100%" height="100%"></use>
                                    </svg>
                                </span>
                            </p>
                            <p className="form__password">
                                <label htmlFor="password2">{t('newPassword.newPasswordAgainLabel')}</label>
                                <input
                                    type={password2Visible ? "text" : "password"}
                                    id="password2"
                                    value={password2}
                                    onChange={(e) => setPassword2(e.target.value)}
                                    required
                                    autoComplete="new-password"
                                />
                                <span className="icon icon--eye" onClick={togglePassword2Visibility}>
                                    <svg className="icon__svg">
                                        <use xlinkHref={`${icons}#eye`} x="0" y="0" width="100%" height="100%"></use>
                                    </svg>
                                </span>
                            </p>
                            <ul className="form__check" style={{ textAlign: "left" }}>
                                <li className={isPasswordLengthValid ? 'is-checked' : ''}>
                                    {t('tryFreeSection.form.passwordCriteria.length')}
                                </li>
                                <li className={isPasswordLettersValid ? 'is-checked' : ''}>
                                    {t('tryFreeSection.form.passwordCriteria.letters')}
                                </li>
                                <li className={isPasswordNumberValid ? 'is-checked' : ''}>
                                    {t('tryFreeSection.form.passwordCriteria.number')}
                                </li>
                                <li className={doPasswordsMatch ? 'is-checked' : ''}>
                                    {t('tryFreeSection.form.passwordCriteria.match')}
                                </li>
                            </ul>
                            <p>
                                <input className="btn" type="submit" value={t('newPassword.sendNewPasswordBtn')} />
                            </p>
                        </div>
                    </form>
                </article>
            </section>
        </>
    );
};

export default NewPassword;
