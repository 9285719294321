import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import HeroSection from '../components/HeroSection';
import BenefitsSection from '../components/BenefitsSection';
import CertifySection from '../components/CertifySection';
import ComparisonSection from '../components/ComparisonSection';
import SetupSection from '../components/SetupSection';
import ForWhomSection from '../components/ForWhomSection';
import ReferenceSection from '../components/ReferenceSection';
import FAQSection from '../components/FAQSection';
import AdvisioSection from '../components/AdvisioSection';
import TryPermoniQSection from '../components/TryPermoniqSection';
import ModalConsultation from '../components/ModalConsultation';

import useUserDetails from '../hooks/useUserDetails';
import { AppContext } from '../context/AppContext';

const Home = () => {
  const { t } = useTranslation();
  const { isLogged } = useContext(AppContext);
  const timeoutRef = useRef(null);

  useUserDetails();

  useEffect(() => {
    const sections = document.querySelectorAll('section[id]');
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Zrušit předchozí timeout, pokud nějaký existuje
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          // Nastavit nový timeout se zpožděním
          timeoutRef.current = setTimeout(() => {
            const id = entry.target.id;
            window.history.replaceState(null, '', `#${id}`);
          }, 600); // Zpoždění 600ms
        } else {
          // Pokud sekce není viditelná a žádné jiné sekce nejsou, odstranit hash z URL
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          
          // Odstraní hash pouze, pokud žádná sekce není viditelná
          const visibleSection = entries.find(e => e.isIntersecting);
          if (!visibleSection) {
            timeoutRef.current = setTimeout(() => {
              window.history.replaceState(null, '', window.location.pathname);
            }, 600); // Zpoždění 600ms
          }
        }
      });
    }, {
      threshold: 0.7 // Sekce je považována za viditelnou, pokud je alespoň 50% viditelných
    });

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      sections.forEach(section => {
        observer.unobserve(section);
      });
      // Vyčistit timeout při odchodu komponenty
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <HeroSection id={t('paths.firstSection')} />
      <BenefitsSection id={t('paths.benefits')} />
      <CertifySection id={t('paths.certify')} />
      <ComparisonSection id={t('paths.comparison')} />
      <SetupSection id={t('paths.howToSetUp')} />
      <ForWhomSection id={t('paths.forWhom')} />
      <ReferenceSection id={t('paths.references')} />
      <FAQSection id={t('paths.faq')} />
      <AdvisioSection id={t('paths.advisio')} />
      {
        !isLogged &&
        <TryPermoniQSection id={t('paths.tryFree')} />
      }
      <ModalConsultation id={t('paths.consultation')} />
    </>
  );
};

export default Home;
