import React, { useEffect, useState, useContext, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import { AppContext } from '../context/AppContext';
import SimpleAlert from './SimpleAlert';
import useUserDetails from '../hooks/useUserDetails';

import { useTranslation } from 'react-i18next';

import logoYoutube from "../img/logos/logo-youtube.svg"
import logoGmail from "../img/logos/logo-gmail.svg"
import logoGoogle from "../img/logos/logo-google.svg"
import logoDiscover from "../img/logos/logo-discover.svg"
import logoDisplay from "../img/logos/logo-display.svg"
import plusDarkIcon from "../img/icons/plusDark.svg";
import minusIcon from "../img/icons/minus.svg"
import axiosInstance from './AxiosConfig';

import { BsStars } from "react-icons/bs";
import { useParams } from 'react-router-dom';

import ModalSendDescription from './ModalSendDescription';

const ModalSettingsLoading = ({ isCampaignsSettingModal, handleModal, setSaveSuccessful }) => {
    const { t } = useTranslation();
    const { lng } = useParams()

    const aiRef = useRef(null)

    const [isActive, setIsActive] = useState(true)
    const [isSquareImage, setIsSquareImage] = useState(false)
    const [isRectImage, setIsRectImage] = useState(false)
    const [isEnoughImages, setIsEnoughImages] = useState(false)
    const [isLogo, setIsLogo] = useState(false)
    const [isSquareLogo, setIsSquareLogo] = useState(false);
    const [isHeadline, setIsHeadline] = useState(false)
    const [isShortHeaders, setIsShortHeaders] = useState(false)
    const [isShortDesc, setIsShortDesc] = useState(false)
    const [isDescs, setIsDescs] = useState(false)
    const [isFileInputClicked, setIsFileInputClicked] = useState(false);

    const { campaignAssetsObject,
        setCampaignAssetsObject,
        setIsCampaignDeactivated,
        setIsUserDataLoading,
        selectedImages,
        setSelectedImages,
        selectedLogos,
        setLogos,
        headline,
        setHeadline,
        longHeadline,
        setLongHeadline,
        shortDescPart1,
        setShortDescPart1,
        shortDescPart2,
        setShortDescPart2,
        descs,
        setDescs,
        deleteAll,
        hasItems,
        setHasItems,
        showAlert,
        closeAlert,
        isAlertVisible,
        alertMessage,
        uuid,
        selectedShop,
        openDescriptionModal,
        setOpenDescriptionModal
    } = useContext(AppContext)

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                handleModal()
            }
        };

        if (isCampaignsSettingModal) {
            document.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isCampaignsSettingModal]);


    useEffect(() => {
        // Limit selectedImages to a maximum of 15
        const limitedSelectedImages = selectedImages.slice(0, 20);

        // Remove duplicate selectedImages based on a unique property, e.g., 'base64'
        const uniqueSelectedImages = [];
        limitedSelectedImages.forEach((image) => {
            // Check that the image is not already in logos
            if (
                !uniqueSelectedImages.some((item) => item.base64.split(",")[1] === image.base64.split(",")[1]) &&
                !selectedLogos.some((logo) => logo.base64.split(",")[1] === image.base64.split(",")[1]) // Check if not in logos
            ) {
                uniqueSelectedImages.push(image);
            } else {
                showAlert(`Obrázek ${image.name} je již nahrán jako logo nebo jako jiný obrázek!`, "danger");
            }
        });

        // Update selectedImages if the unique list differs
        if (uniqueSelectedImages.length !== selectedImages.length) {
            setSelectedImages(uniqueSelectedImages);
        }

        // Calculate counts for shapes
        const squareCount = uniqueSelectedImages.filter(
            (image) => image.shape === 'square'
        ).length;
        const rectCount = uniqueSelectedImages.filter(
            (image) => image.shape === 'rect'
        ).length;

        // Update shape states
        setIsSquareImage(squareCount > 0);
        setIsRectImage(rectCount > 0);

        // selectedImages count control
        setIsEnoughImages(isSquareImage && isRectImage && selectedImages.length > 2);

        // Limit selectedLogos to a maximum of 5
        const limitedLogos = selectedLogos.slice(0, 5);

        // Remove duplicate selectedLogos based on a unique property, e.g., 'base64'
        const uniqueLogos = [];
        limitedLogos.forEach((logo) => {
            // Check that the logo is not already in images
            if (
                !uniqueLogos.some((item) => item.base64 === logo.base64) &&
                !selectedImages.some((image) => image.base64.split(",")[1] === logo.base64.split(",")[1]) // Check if not in images
            ) {
                uniqueLogos.push(logo);
            } else {
                showAlert(`Logo ${logo.name} je již nahráno jako obrázek nebo jako jiné logo!`, "danger");
            }
        });

        // Update selectedLogos if the unique list differs
        if (uniqueLogos.length !== selectedLogos.length) {
            setLogos(uniqueLogos);
        }

        // Count square logos
        const squareLogoCount = uniqueLogos.filter((logo) => logo.shape === 'square').length;

        // Update state for square logo presence
        setIsSquareLogo(squareLogoCount > 0);
        setIsLogo(uniqueLogos.length > 0);

    }, [selectedImages, selectedLogos, isSquareImage, isRectImage, isLogo]);


    useEffect(() => {
        // longHeader control
        const longHeaderValidCount = headline.filter(headline => headline.length >= 3).length;
        longHeaderValidCount > 2 ? setIsHeadline(true) : setIsHeadline(false);

        // longHeadline control
        let shortHeadersCount = 0;
        longHeadline.forEach((oneShortHeader) => {
            oneShortHeader.length > 2 && shortHeadersCount++;
        });

        shortHeadersCount > 1 ? setIsShortHeaders(true) : setIsShortHeaders(false);

        // descs control (check each desc length)
        let descsValidCount = 0;
        descs.forEach((desc, index) => {
            if (index === 0) {
                if (desc.length >= 12 && desc.length <= 60) {
                    descsValidCount++;
                }
            } else {
                if (desc.length >= 12 && desc.length <= 90) {
                    descsValidCount++;
                }
            }
        });

        descsValidCount > 1 ? setIsDescs(true) : setIsDescs(false); // Check if at least two valid descriptions

    }, [headline, longHeadline, descs]);


    useEffect(() => {
        aiRef.current.classList.add("moving-border")
    }, [])


    const handleActive = () => {
        setIsActive(!isActive)  // Dočasně nepoužíváno z důvodu nezobrazení preview.
    }

    const closeModal = (e) => {
        if (e.target === e.currentTarget) {
            handleModal();
        }
    }


    const handleItem = (e) => {
        let parent = e.target.parentElement

        if (!e.target.className.includes("settings__item")) {
            if (parent.classList.value.includes("is-open")) {
                parent.classList.remove("is-open")
            } else {
                parent.classList.add("is-open")
            }
        }
    }

    const handleImageChange = (e) => {

        const files = e.target.files; // Get all selected files
        const fileArray = [...files]; // Convert FileList to an array

        if (files && selectedImages.length + fileArray.length <= 15) {
            fileArray.forEach((file, index) => {
                let newImage = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    id: new Date().getTime() + index // Ensure unique IDs for each image
                };

                const reader = new FileReader();
                reader.onload = () => {
                    newImage.base64 = reader.result;
                    let img = new Image();
                    img.onload = () => {
                        newImage.width = img.width;
                        newImage.height = img.height;

                        if (newImage.width === newImage.height) {
                            if (newImage.size / 1024 <= 5120 && newImage.width >= 300 && newImage.height >= 300) {
                                newImage.shape = "square";
                                setSelectedImages((prevImg) => [...prevImg, newImage]);
                                setIsSquareImage(true);
                            } else if (newImage.size / 1024 > 5120) {
                                showAlert(`Maximální velikost obrázku ${newImage.name} je 5120 kB!`, "danger");

                            } else if (newImage.width < 300 || newImage.height < 300) {
                                showAlert(`Minimální výška a šířka čtvercového obrázku ${newImage.name} je 300px!`, "danger");

                            } else {
                                showAlert(`Vyskytla se chyba u obrázku ${newImage.name}!`, "danger");

                            }
                        } else if ((newImage.width / newImage.height).toFixed(2) === "1.91") {
                            if (newImage.size / 1024 <= 5120 && newImage.width >= 600 && newImage.height >= 314) {
                                newImage.shape = "rect";
                                setSelectedImages((prevImg) => [...prevImg, newImage]);
                                setIsRectImage(true);
                            } else if (newImage.size / 1024 > 5120) {
                                showAlert(`Maximální velikost obrázku ${newImage.name} je 5120 kB!`, "danger");

                            } else if (newImage.width < 600 || newImage.height < 314) {
                                showAlert(`Obrázek ${newImage.name} na šířku má minimální šířku 600px a minimální výšku 314px!`, "danger");

                            } else {
                                showAlert(`Vyskytla se chyba u obrázku ${newImage.name}!`, "danger");

                            }
                        } else {
                            showAlert(`Obrázek ${newImage.name} nemohl být nahrán z důvodu špatných rozměrů!`, "danger");

                        }
                    };

                    img.src = newImage.base64;
                };

                reader.readAsDataURL(file);
            });
        } else if (selectedImages.length >= 15) {
            showAlert("Maximální počet obrázků je 15!", "danger");

        }

        e.target.value = "";
    };

    const handleDeleteImage = (id) => {
        setSelectedImages(selectedImages.filter((oneImage) => oneImage.id !== id))
    }

    const handleLogoChange = (e) => {

        const file = e.target.files;
        const fileArray = [...file]

        if (file && selectedLogos.length < 5) {

            fileArray.forEach((oneFile, index) => {
                let newLogo = {
                    name: oneFile.name,
                    size: oneFile.size,
                    type: oneFile.type,
                    id: new Date().getTime() + index
                }
                const reader = new FileReader();
                reader.onload = () => {
                    newLogo.base64 = reader.result
                    let img = new Image()

                    img.onload = () => {
                        newLogo.width = img.width
                        newLogo.height = img.height

                        if (img.width === img.height) { // Square logo
                            if (img.width >= 128 && img.height >= 128) {
                                if (newLogo.size / 1024 <= 5120) {
                                    newLogo.shape = "square";
                                    setLogos((prevLogo) => [...prevLogo, newLogo]);
                                    setIsSquareLogo(true);
                                } else {
                                    showAlert(`Velikost loga ${newLogo.name} nesmí být větší než 5120kB !`, "danger");
                                }
                            } else {
                                showAlert(`Výška a šířka loga ${newLogo.name} musí být minimálně 128px!`, "danger");
                            }
                        } else if ((img.width / img.height).toFixed(2) === "4.00") { // Rectangular logo
                            if (img.width >= 512 && img.height >= 128) {
                                if (newLogo.size / 1024 <= 5120) {
                                    newLogo.shape = "rect";
                                    setLogos((prevLogo) => [...prevLogo, newLogo]);
                                    setIsLogo(true);
                                } else {
                                    showAlert(`Velikost loga ${newLogo.name} nesmí být větší než 5120kB !`, "danger");
                                }
                            } else {
                                showAlert(`Široké logo ${newLogo.name} musí mít minimální rozměry 512x128px!`, "danger");
                            }
                        } else {
                            showAlert(`Logo ${newLogo.name} má nesprávné rozměry!`, "danger");
                        }

                    };

                    img.src = newLogo.base64

                }
                reader.readAsDataURL(oneFile);
            })

        } else if (selectedLogos.length >= 5) {
            showAlert("Maximální počet log je 5!", "danger")
        }

        e.target.value = ""

    }

    const handleDeleteLogo = (id) => {
        setLogos(selectedLogos.filter((oneLogo) => oneLogo.id !== id))
    }

    const handleInputChange = (index, value) => {
        const newShortHeaders = [...longHeadline];
        newShortHeaders[index] = value;
        setLongHeadline(newShortHeaders);
    };

    const addLongHeader = () => {
        if (headline.length < 15) {
            setHeadline(prevState => [...prevState, ""]);
        }
    };

    const removeLongHeader = (index) => {
        if (headline.length > 3) {
            const newHeadlines = headline.filter((_, i) => i !== index);
            setHeadline(newHeadlines.length >= 3 ? newHeadlines : ["", "", ""]);
        }
    };

    const handleLongHeaderChange = (index, value) => {
        const newHeadlines = [...headline];
        newHeadlines[index] = value;
        setHeadline(newHeadlines);
    };


    const sendAssetObject = async (campaign_id, asset_id, objectForSend) => {
        try {
            setIsUserDataLoading(true);
            const response = await axiosInstance.post(`campaign/${campaign_id}/assets/${asset_id}/update/`, objectForSend);
            setSaveSuccessful(true);
            handleModal();
            setIsUserDataLoading(false);
            window.scrollTo(0, 0)
            return response;  // Vrátíme celý response objekt
        } catch (error) {
            setIsUserDataLoading(false);
            console.log(error.response);
            return null;  // Pokud dojde k chybě, vrátíme null
        }
    }

    const onSave = async (e) => {
        e.preventDefault();

        // Kontrola na duplicity
        const trimmedHeadlines = headline.map(h => h.trim());
        const trimmedLongHeadlines = longHeadline.map(lh => lh.trim());
        const trimmedDescs = descs.map(d => d.trim());

        const hasDuplicateHeadlines = new Set(trimmedHeadlines).size !== trimmedHeadlines.length;
        const hasDuplicateLongHeadlines = new Set(trimmedLongHeadlines).size !== trimmedLongHeadlines.length;
        const hasDuplicateDescs = new Set(trimmedDescs).size !== trimmedDescs.length;

        const isValidDescs = trimmedDescs.every((desc, index) =>
            (index === 0 && desc.length >= 12 && desc.length <= 60) ||
            (index > 0 && desc.length >= 12 && desc.length <= 90)
        );

        const errors = [];

        if (!isEnoughImages) {
            errors.push("Není dostatek validních obrázků. Ujistěte se, že máte alespoň 3 obrázky, které splňují požadované rozměry.");
        }
        if (!isLogo) {
            errors.push("Chybí validní logo. Ujistěte se, že jste nahráli alespoň jedno logo.");
        }
        if (!isHeadline) {
            errors.push("Chybí nebo nejsou správně vyplněny nadpisy. Musíte mít alespoň 3 nadpisy s délkou mezi 3 až 30 znaky.");
        }
        if (!isShortHeaders) {
            errors.push("Chybí nebo nejsou správně vyplněny dlouhé nadpisy. Ujistěte se, že máte alespoň 2 dlouhé nadpisy s délkou mezi 3 až 90 znaky.");
        }
        if (!isValidDescs) {
            errors.push("Popisy nesplňují požadavky. Ujistěte se, že první popis má délku mezi 12 až 60 znaky a další mezi 12 až 90 znaky, a že máte minimálně 2 popisy.");
        }

        // Přidání kontrol na duplicity
        if (hasDuplicateHeadlines) {
            errors.push("Duplicitní nadpisy nejsou povoleny. Ujistěte se, že všechny nadpisy jsou unikátní.");
        }
        if (hasDuplicateLongHeadlines) {
            errors.push("Duplicitní dlouhé nadpisy nejsou povoleny. Ujistěte se, že všechny dlouhé nadpisy jsou unikátní.");
        }
        if (hasDuplicateDescs) {
            errors.push("Duplicitní popisy nejsou povoleny. Ujistěte se, že všechny popisy jsou unikátní.");
        }

        if (errors.length > 0) {
            showAlert(errors.join('\n'), "danger");
            return;
        }

        try {
            // Naplnění objektu novými hodnotami, včetně zachování BUSINESS_NAME
            const updatedObject = {
                ...campaignAssetsObject,
                items: [
                    ...(campaignAssetsObject.items || []).filter(item => item.type === "BUSINESS_NAME"), // Zachování položky BUSINESS_NAME
                    ...headline
                        .filter(oneHeadline => oneHeadline) // Filtrovat pouze ne-prázdné headliny
                        .map(oneHeadline => ({
                            name: oneHeadline,
                            type: "HEADLINE"
                        })),
                    ...(shortDescPart1 ? [{
                        name: shortDescPart1,
                        type: "DESCRIPTION"
                    }] : []), // Přidat první část popisu jen pokud není prázdná
                    ...(shortDescPart2 ? [{
                        name: shortDescPart2,
                        type: "DESCRIPTION"
                    }] : []), // Přidat druhou část popisu jen pokud není prázdná
                    ...longHeadline
                        .filter(oneLongHeadline => oneLongHeadline) // Filtrovat pouze ne-prázdné long headliny
                        .map(oneLongHeadline => ({
                            name: oneLongHeadline,
                            type: "LONG_HEADLINE"
                        })),
                    ...descs
                        .filter(oneDesc => oneDesc) // Filtrovat pouze ne-prázdné descs
                        .map((oneDesc, index) => ({
                            name: oneDesc,
                            type: "DESCRIPTION",
                        })),
                    ...selectedLogos
                        .filter(oneLogo => oneLogo.name) // Filtrovat pouze loga s ne-prázdným name
                        .map(oneLogo => ({
                            name: oneLogo.name,
                            type: oneLogo.shape === "square" ? "LOGO" : "LANDSCAPE_LOGO",  // Rozlišení mezi čtvercovým a širokým logem
                            original_image: oneLogo.base64
                        })),
                    ...selectedImages
                        .filter(oneImage => oneImage.name) // Filtrovat pouze obrázky s ne-prázdným name
                        .map(oneImage => ({
                            name: oneImage.name,
                            type: oneImage.shape === "square" ? "SQUARE_MARKETING_IMAGE" : "MARKETING_IMAGE",
                            original_image: oneImage.base64
                        }))
                ]
            };

            // Vypočítání velikosti payloadu
            const payloadSize = new Blob([JSON.stringify({ items: [...updatedObject.items] })]).size;

            // Odeslání dat po aktualizaci objektu
            const response = await sendAssetObject(updatedObject.campaign_id, updatedObject.asset_id, { items: [...updatedObject.items] });

            // Kontrola, zda první request proběhl úspěšně
            if (response && (response.status === 200 || response.status === 201)) {
                try {
                    if (!hasItems) {
                        try {
                            await axiosInstance.patch(`campaign/${campaignAssetsObject.campaign_id}/update/`, {
                                campaign_status: "paused"
                            });
                        } catch (error) {
                            console.log("Update campaign_status error", error);
                        }
                    }

                    // Vyprázdnění objektu po úspěšném odeslání (případně dle potřeby)
                    setCampaignAssetsObject((prevState) => ({
                        ...prevState,
                        items: [
                            ...prevState.items.filter(item => item.type === "BUSINESS_NAME") // Zachování pouze BUSINESS_NAME po odeslání
                        ]
                    }));
                    setHasItems(true);
                    deleteAll();
                    setIsCampaignDeactivated(false);

                } catch (error) {
                    console.error("Chyba při aktualizaci stavu kampaně:", error);
                    showAlert("Došlo k chybě při aktualizaci stavu kampaně. Zkuste to prosím znovu.", "danger");
                }
            } else {
                console.error("Chyba při odesílání dat: Status není 200 nebo 201.");
                showAlert("Došlo k chybě při ukládání změn. Zkuste to prosím znovu.", "danger");
            }
        } catch (error) {
            console.error("Chyba při odesílání dat:", error.data.error);
            showAlert("Došlo k chybě při ukládání změn. Zkuste to prosím znovu.", "danger");
        }
    };






    const addLongHeadline = () => {
        if (longHeadline.length < 5) {  // Limit na maximální počet dlouhých nadpisů
            setLongHeadline(prevState => [...prevState, ""]);
        }
    };

    const removeLongHeadline = (index) => {
        if (longHeadline.length > 2) {  // Zabrání odstranění posledního nadpisu
            setLongHeadline(prevState => prevState.filter((_, i) => i !== index));
        }
    };

    const handleDescChange = (index, value) => {
        const newDescs = [...descs];
        newDescs[index] = value;
        setDescs(newDescs);
    };



    const addDesc = () => {
        if (descs.length < 5) {  // Limit na maximální počet popisů
            setDescs(prevState => [...prevState, ""]);
        }
    };

    const removeDesc = (index) => {
        if (descs.length > 2) {  // Zabrání odstranění posledního popisu
            setDescs(prevState => prevState.filter((_, i) => i !== index));
        }
    };


    const onDropImages = (acceptedFiles) => {
        acceptedFiles.forEach((file, index) => {
            let newImage = {
                name: file.name,
                size: file.size,
                type: file.type,
                id: new Date().getTime() + index
            };

            const reader = new FileReader();
            reader.onload = () => {
                newImage.base64 = reader.result;
                let img = new Image();
                img.onload = () => {
                    newImage.width = img.width;
                    newImage.height = img.height;

                    if (newImage.width === newImage.height) {
                        if (newImage.size / 1024 <= 5120 && newImage.width >= 300 && newImage.height >= 300) {
                            newImage.shape = "square";
                            setSelectedImages((prevImg) => [...prevImg, newImage]);
                            setIsSquareImage(true);
                        } else {
                            showAlert(`Maximální velikost obrázku ${newImage.name} je 5120 kB!`, "danger");
                        }
                    } else if ((newImage.width / newImage.height).toFixed(2) === "1.91") {
                        if (newImage.size / 1024 <= 5120 && newImage.width >= 600 && newImage.height >= 314) {
                            newImage.shape = "rect";
                            setSelectedImages((prevImg) => [...prevImg, newImage]);
                            setIsRectImage(true);
                        } else {
                            showAlert(`Maximální velikost obrázku ${newImage.name} je 5120 kB!`, "danger");
                        }
                    } else {
                        showAlert(`Obrázek ${newImage.name} nemohl být nahrán z důvodu špatných rozměrů!`, "danger");
                    }
                };
                img.src = newImage.base64;
            };
            reader.readAsDataURL(file);
        });
    };

    const { getRootProps: getRootPropsImages, getInputProps: getInputPropsImages, isDragActive: isDragActiveImages } = useDropzone({
        onDrop: onDropImages,
        accept: 'image/jpeg, image/png, image/webp, image/jpg',
        multiple: true,
        maxFiles: 15,
        noClick: true, // Zabráníme kliknutí a otevření dialogu Dropzone, použijeme vlastní input
        onDragEnter: () => setIsFileInputClicked(false), // Při přetažení resetujeme jakýkoliv stav kliknutí
    });


    const onDropLogos = (acceptedFiles) => {
        acceptedFiles.forEach((file, index) => {
            let newLogo = {
                name: file.name,
                size: file.size,
                type: file.type,
                id: new Date().getTime() + index
            };

            const reader = new FileReader();
            reader.onload = () => {
                newLogo.base64 = reader.result;
                let img = new Image();
                img.onload = () => {
                    newLogo.width = img.width;
                    newLogo.height = img.height;

                    if (img.width === img.height) {
                        if (newLogo.size / 1024 <= 5120 && img.width >= 128 && img.height >= 128) {
                            newLogo.shape = "square";
                            setLogos((prevLogo) => [...prevLogo, newLogo]);
                            setIsSquareLogo(true);
                        } else {
                            showAlert(`Velikost loga ${newLogo.name} nesmí být větší než 5120 kB!`, "danger");
                        }
                    } else if ((img.width / img.height).toFixed(2) === "4.00") {
                        if (newLogo.size / 1024 <= 5120 && img.width >= 512 && img.height >= 128) {
                            newLogo.shape = "rect";
                            setLogos((prevLogo) => [...prevLogo, newLogo]);
                            setIsLogo(true);
                        } else {
                            showAlert(`Velikost loga ${newLogo.name} nesmí být větší než 5120 kB!`, "danger");
                        }
                    } else {
                        showAlert(`Logo ${newLogo.name} má nesprávné rozměry!`, "danger");
                    }
                };
                img.src = newLogo.base64;
            };
            reader.readAsDataURL(file);
        });
    };

    const {
        getRootProps: getRootPropsLogos,
        getInputProps: getInputPropsLogos,
        isDragActive: isDragActiveLogos,
    } = useDropzone({
        onDrop: onDropLogos,
        accept: 'image/jpeg, image/png, image/webp, image/jpg',
        multiple: true,
        maxFiles: 5,
        noClick: true,
        onDragEnter: () => setIsFileInputClicked(false)
    });

    const onGenerateText = async (textType = 'all') => {
        setIsUserDataLoading(true);
        try {
            const generateTextResponse = await axiosInstance.post(`shop/suggestion/campaign_text/${uuid}/`, {
                "campaign_type": "google-pmax",
                "text_type": textType.toUpperCase(), // Dynamicky doplní text_type podle parametru
                "description": selectedShop.description
            });
    
            const generatedText = generateTextResponse?.data;
    
            if (generatedText !== "Description is required") {
                // Extrahování textů podle typu z response
                const headlines = generatedText.filter(item => item.type === "HEADLINE").map(item => item.text);
                const longHeadlines = generatedText.filter(item => item.type === "LONG_HEADLINE").map(item => item.text);
                const descriptions = generatedText.filter(item => item.type === "DESCRIPTION").map(item => item.text);
    
                if (textType === 'all') {
                    // Pro typ 'all' nahrazujeme všechny stavy novými hodnotami
                    setHeadline(headlines);
                    setLongHeadline(longHeadlines);
                    setDescs(descriptions);
                } else {
                    // Pro jednotlivé typy (headline, long, desc)
                    if (textType === 'headline') {
                        setHeadline(prevHeadlines => {
                            const newHeadline = headlines[headlines.length - 1];
                            if (prevHeadlines[prevHeadlines.length - 1] === "") {
                                // Přepiš poslední prázdnou hlavičku
                                const updatedHeadlines = [...prevHeadlines];
                                updatedHeadlines[updatedHeadlines.length - 1] = newHeadline;
                                return updatedHeadlines;
                            } else {
                                // Přidej novou hlavičku
                                return [...prevHeadlines, newHeadline];
                            }
                        });
                    }
    
                    if (textType === 'long') {
                        setLongHeadline(prevLongHeadlines => {
                            const newLongHeadline = longHeadlines[longHeadlines.length - 1];
                            if (prevLongHeadlines[prevLongHeadlines.length - 1] === "") {
                                // Přepiš poslední prázdnou dlouhou hlavičku
                                const updatedLongHeadlines = [...prevLongHeadlines];
                                updatedLongHeadlines[updatedLongHeadlines.length - 1] = newLongHeadline;
                                return updatedLongHeadlines;
                            } else {
                                // Přidej novou dlouhou hlavičku
                                return [...prevLongHeadlines, newLongHeadline];
                            }
                        });
                    }
    
                    if (textType === 'desc') {
                        setDescs(prevDescs => {
                            const newDesc = descriptions[descriptions.length - 1];
                            if (prevDescs[prevDescs.length - 1] === "") {
                                // Přepiš poslední prázdný popis
                                const updatedDescs = [...prevDescs];
                                updatedDescs[updatedDescs.length - 1] = newDesc;
                                return updatedDescs;
                            } else {
                                // Přidej nový popis
                                return [...prevDescs, newDesc];
                            }
                        });
                    }
                }
    
                showAlert(t("campaignModal.infoMessages.textGeneratedInfoMsg"), "info");
            } else {
                setOpenDescriptionModal(true);
            }
    
            console.log("Generated Text:", generateTextResponse);
        } catch (error) {
            console.log("Generate Text Error", error.message);
        } finally {
            setIsUserDataLoading(false);
        }
    };
    

    return (
        <div className={`modal modal--settings ${isCampaignsSettingModal && "is-open"}`} id="modal-1" onClick={closeModal}>

            {
                openDescriptionModal &&
                <ModalSendDescription />
            }

            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    type={alertMessage.type}
                    onClose={closeAlert} />
            )}
            <div className="modal__body">

                <div className="modal__content">

                    <div className="box box--settings">
                        <form className="form" autoComplete='off'>



                            <div className="tabs">
                                <ul>
                                    <li onClick={() => handleActive()} className={`tabs__item ${isActive && "is-active"}`} data-tab="settings">Nastavení produktů</li>
                                    <li onClick={() => handleActive()} className={`tabs__item ${!isActive && "is-active"}`} data-tab="preview">Náhled produktů</li>
                                </ul>
                            </div>

                            <div className={`ai_settings__item`} ref={aiRef}>
                                <p>
                                    {t("campaignModal.infoTextAi")}
                                </p>
                                <button className="ai_btn" onClick={(e) => {
                                    e.preventDefault()
                                    onGenerateText()
                                    e.target.blur()

                                }}><BsStars />{t("campaignModal.aiBtn")}</button>
                            </div>
                            <div className={`settings ${isActive && `is-open`}`}>

                                <div className={`settings__item tips_recom ${hasItems && "is-open"}`} onClick={handleItem}>
                                    <h2 className="settings__title">
                                        Tipy a doporučení pro nastavení kampaní
                                    </h2>

                                    <div className="settings__content">
                                        <ul>
                                            <li>Nevyplňujte pouze nezbytné minimum obsahu. Čím více unikátních obrázků, nadpisů a popisů nahrajete, tím více kombinací reklam systém vytvoří.</li>
                                            <li>Myslete na to, že se všechny prvky v kampani mezi sebou kombinují, jednotlivé možnosti tak musí být navzájem kompatibilní. Pohlídejte si jednotlivé texty, aby se v nich informace neopakovaly a nevytvářely v reklamě nesmyslný obsah. Propojte obsah nadpisu a popisného řádku.</li>
                                            <li>Používejte co nejvíce kvalitních podkladů (obrázky, loga, název firmy, texty).</li>
                                            <li>V textech se vyhýbejte obecným frázím. Naveďte uživatele a řekněte mu, co má dál udělat.</li>
                                            <li>Jako inspiraci pro text reklamy použijte formulace z úspěšných stránek na vašem webu.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        Nadpis
                                        <small>Minimálně 3 nadpisy jsou povinné a musí mít 3 - 30 znaků. Je možné přidat až 15 nadpisů.</small>
                                    </h2>

                                    <div className="settings__content">
                                        {headline.map((headlineItem, index) => (
                                            <div key={index}>
                                                <div className="headline-container">
                                                    <input
                                                        name={`longtitle-${index + 1}`}
                                                        id={`longtitle-${index + 1}`}
                                                        value={headlineItem}
                                                        onChange={(e) => handleLongHeaderChange(index, e.target.value)}
                                                        className="js-form-limit no-margin"
                                                        maxLength="30"
                                                    />
                                                    <img
                                                        src={minusIcon}
                                                        className="remove-headline-minus-icon"
                                                        alt="Remove headline"
                                                        onClick={() => removeLongHeader(index)}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                    {/* Zobrazí ikonu plus jen u posledního pole */}
                                                    {index === headline.length - 1 && (
                                                        <div className='add_and_ai_wrapper'>
                                                            <div className='plus_btn_wrapper' onClick={addLongHeader}>
                                                                <img
                                                                    src={plusDarkIcon}
                                                                    className="add-headline-plus-icon"
                                                                    alt="Add headline"
                                                                    
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                                <p>Nadpis</p>
                                                            </div>
                                                            <button className="ai_btn" onClick={(e) => {
                                                                e.preventDefault()
                                                                onGenerateText("headline")
                                                                e.target.blur()

                                                            }}><BsStars />{t("campaignModal.aiBtn")}</button>
                                                        </div>
                                                    )}
                                                </div>
                                                <span className="form__limit" style={{ marginRight: "4rem", marginBlock: "10px" }}>
                                                    <em>{headlineItem.length}</em>/30 znaků
                                                </span>
                                            </div>
                                        ))}
                                    </div>


                                </div>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>
                                    <h2 className="settings__title">
                                        5 dlouhých nadpisů
                                        <small>Minimálně 2 dlouhé nadpisy jsou povinné. Dlouhé nadpisy musí mít 3 - 90 znaků.</small>
                                    </h2>

                                    <div className="settings__content">
                                        {longHeadline.map((headline, index) => (
                                            <div key={index}>
                                                <span className="form__limit">
                                                    <em>{headline.length}</em>/90 znaků
                                                </span>
                                                <div className="headline-container">
                                                    <input
                                                        name={`title-1-${index + 1}`}
                                                        id={`title-1-${index + 1}`}
                                                        className="js-form-limit no-margin"
                                                        value={headline}
                                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                                        maxLength="90"
                                                    />
                                                    <img
                                                        src={minusIcon}
                                                        className='remove-headline-minus-icon'
                                                        alt='Remove long headline'
                                                        onClick={() => removeLongHeadline(index)}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                    <img
                                                        src={plusDarkIcon}
                                                        className='add-headline-plus-icon'
                                                        alt="Add long headline"
                                                        onClick={addLongHeadline}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>
                                    <h2 className="settings__title">
                                        Popisy
                                        <small>První popis musí mít 12 - 60 znaků, další popisy mohou mít až 90 znaků. Jsou povinné minimálně 2 popisy.</small>
                                    </h2>

                                    <div className="settings__content">
                                        {descs.map((desc, index) => (
                                            <div key={index}>
                                                <span className="form__limit">
                                                    <em>{desc.length}</em>/{index === 0 ? 60 : 90} znaků
                                                </span>
                                                <div className="headline-container">
                                                    <input
                                                        name={`desc-${index + 1}`}
                                                        id={`desc-${index + 1}`}
                                                        className="js-form-limit no-margin"
                                                        value={desc}
                                                        onChange={(e) => handleDescChange(index, e.target.value)}
                                                        maxLength={index === 0 ? 60 : 90}
                                                    />
                                                    <img
                                                        src={minusIcon}
                                                        className='remove-headline-minus-icon'
                                                        alt='Remove description'
                                                        onClick={() => removeDesc(index)}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                    <img
                                                        src={plusDarkIcon}
                                                        className='add-headline-plus-icon'
                                                        alt="Add description"
                                                        onClick={addDesc}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        Obrázky
                                        {hasItems &&
                                            <small>Nahrajte 3–20 obrázků, které se zobrazí v kampaních. Vybírejte nejprodávanější nebo nejatraktivnější produkty, které na první pohled nalákají zákazníka na váš e-shop. Chcete-li třídit sortiment podle kategorií, vložte pro každou z nich alespoň 5 obrázků. Experimentujte s různými vizuály, jako jsou obrázky produktů či lifestylová fota.</small>
                                        }
                                    </h2>

                                    <div className="settings__content">

                                        <div className="settings__note">
                                            <p>Formáty obrázků:</p>
                                            <ul>
                                                <li>Obrázek na šířku (1,91 : 1)</li>
                                                <ul>
                                                    <li>Doporučená velikost: 1200 × 628 px</li>
                                                    <li>Minimální velikost: 600 × 314</li>
                                                </ul>
                                                <li>Čtvercový obrázek (1 : 1)</li>
                                                <ul>
                                                    <li>Doporučená velikost: 1200 × 1200 px</li>
                                                    <li>Minimální velikost: 300 × 300</li>
                                                </ul>
                                            </ul>
                                        </div>

                                        <div {...getRootPropsImages()} style={{
                                            outline: isDragActiveImages ? "2px dashed #22e06c" : "2px dashed transparent"
                                        }} className="dropzone">
                                            <input {...getInputPropsImages()} />
                                            <p className="form__file">
                                                <label>
                                                    <input
                                                        type="file"
                                                        id="file-1"
                                                        name="file-1"
                                                        multiple
                                                        accept="image/png, image/jpeg, image/webp, image/jpg"
                                                        onChange={handleImageChange}
                                                    />
                                                    <span><span className="btn btn--secondary">Vyberte soubor z počítače</span></span>
                                                </label>
                                            </p>

                                        </div>


                                        {selectedImages.length > 0 &&
                                            selectedImages.map((oneImage) => {

                                                return (
                                                    <p className="file" key={oneImage.id}>
                                                        <span className="file__image"><img src={oneImage.base64} alt="uploaded image" /></span>
                                                        <span className="file__name"></span>
                                                        <a href="#" onClick={() => handleDeleteImage(oneImage.id)} className="file__delete">
                                                            <span className="icon icon--delete"><svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink"><use xlinkHref="/img/icons.svg#delete" x="0" y="0" width="100%" height="100%"></use></svg></span>
                                                            Smazat
                                                        </a>
                                                    </p>
                                                )
                                            })

                                        }

                                    </div>

                                </div>



                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        Loga
                                        <small>Přidejte 1–5 log své firmy. Logo v reklamách spolu s názvem firmy přináší více konverzí, pomůže zákazníkům na první pohled poznat vaši značku, odliší vás od konkurence, zvýší důvěryhodnost, vylepší skóre kvality i CTR.</small>
                                    </h2>

                                    <div className="settings__content">

                                        <div className="settings__note">
                                            <p>Formáty loga:</p>
                                            <ul>
                                                <li>Čtvercové logo (1 : 1)</li>
                                                <ul>
                                                    <li>Doporučená velikost: 1200 × 1200 px</li>
                                                    <li>Minimální velikost: 128 × 128</li>
                                                </ul>
                                                <li>Logo na šířku (4 : 1)</li>
                                                <ul>
                                                    <li>Doporučená velikost: 1200 × 300 px</li>
                                                    <li>Minimální velikost: 512 × 128</li>
                                                </ul>
                                            </ul>
                                        </div>

                                        <div {...getRootPropsLogos()} style={{
                                            outline: isDragActiveLogos ? "2px dashed #22e06c" : "2px dashed transparent"
                                        }} className="dropzone">
                                            <input {...getInputPropsLogos()} />
                                            <p className="form__file">
                                                <label>
                                                    <input
                                                        type="file"
                                                        id="logo-1"
                                                        name="logo-1"
                                                        multiple
                                                        accept="image/png, image/jpeg, image/webp, image/jpg"
                                                        onChange={handleLogoChange}
                                                    />
                                                    <span><span className="btn btn--secondary">Vyberte soubor z počítače</span></span>
                                                </label>
                                            </p>

                                        </div>


                                        {selectedLogos.length > 0 &&
                                            selectedLogos.map((oneLogo) => {
                                                return (
                                                    <p className="file" key={oneLogo.id}>
                                                        <span className="file__image"><img src={oneLogo.base64} alt="uploaded image" /></span>
                                                        <span className="file__name"></span>
                                                        <a href="#" onClick={() => handleDeleteLogo(oneLogo.id)} className="file__delete">
                                                            <span className="icon icon--delete"><svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink"><use xlinkHref="/img/icons.svg#delete" x="0" y="0" width="100%" height="100%"></use></svg></span>
                                                            Smazat
                                                        </a>
                                                    </p>
                                                )
                                            })

                                        }

                                    </div>

                                </div>




                                {/* <div className={`settings__item`} onClick={handleItem}>

                                        <h2 className="settings__title">
                                            4 Dlouhé popisy
                                            <small>Minimálně 1 dlouhý popis je povinný a musí mít 3 - 90 znaků</small>
                                        </h2>

                                        <div className="settings__content">

                                            {descs.map((desc, index) => (
                                                <p key={index}>
                                                    <span className="form__limit">
                                                        <em>{desc.length}</em>/90 znaků
                                                    </span>
                                                    <input
                                                        name={`keyword-1-${index + 1}`}
                                                        id={`keyword-1-${index + 1}`}
                                                        className="js-form-limit"
                                                        value={desc}
                                                        onChange={(e) => {
                                                            const newDescs = [...descs];
                                                            newDescs[index] = e.target.value;
                                                            setDescs(newDescs);
                                                        }}
                                                        maxLength="90"
                                                    />
                                                </p>
                                            ))}
                                        </div>

                                    </div> */}

                            </div>

                            {/* <div className={`preview ${!isActive && "is-open"}`}>

                                <ul className="preview__switch">
                                    <li data-switch="youtube" className="is-active">
                                        <img src={logoYoutube} alt="logo Youtube" width="40" height="40" loading="lazy" />
                                        <em>Youtube</em>
                                    </li>
                                    <li data-switch="gmail">
                                        <img src={logoGmail} alt="logo Gmail" width="40" height="40" loading="lazy" />
                                        <em>Gmail</em>
                                    </li>
                                    <li data-switch="google">
                                        <img src={logoGoogle} alt="logo Google Search" width="40" height="40" loading="lazy" />
                                        <em>Google Search</em>
                                    </li>
                                    <li data-switch="discover">
                                        <img src={logoDiscover} alt="logo Google Discover" width="40" height="40" loading="lazy" />
                                        <em>Google Discover</em>
                                    </li>
                                    <li data-switch="display">
                                        <img src={logoDisplay} alt="logo Google Display" width="40" height="40" loading="lazy" />
                                        <em>Google Display</em>
                                    </li>
                                </ul>

                                <div className="preview__detail youtube is-open">
                                    Youtube
                                </div>

                                <div className="preview__detail gmail">
                                    Gmail
                                </div>

                                <div className="preview__detail google">
                                    Google Search
                                </div>

                                <div className="preview__detail discover">
                                    Google Discover
                                </div>

                                <div className="preview__detail display">
                                    Google Display
                                </div>

                            </div> */}

                            <p className="modal__submit">
                                <button className="btn btn--secondary modal__cancel" onClick={(e) => {
                                    e.preventDefault()
                                    handleModal()
                                }}>Zrušit</button>
                                <button className="btn" onClick={(e) => {
                                    e.preventDefault()
                                    onSave(e)
                                }}>Uložit</button>
                            </p>

                        </form>
                    </div>

                </div>

            </div >
        </div >
    );
}

export default ModalSettingsLoading;
