import React from "react";
import { useTranslation, Trans } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <h2>{t("privacyPolicy.title")}</h2>
      <ul>
        {t("privacyPolicy.items", { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
        <li>
          <Trans
            i18nKey={t("privacyPolicy.linkItem")}
            components={[
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes\"
                target="_blank"
                rel="noopener noreferrer"
              />
            ]}
          />
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
