import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../context/AppContext';
import axiosInstance from './AxiosConfig';
import useUserAppDetails from '../hooks/useUserAppDetails';

import '../css/app.css';
import "../css/styles.css"

import loginGoogle from "../img/login-google.svg"
import loginFacebook from "../img/login-facebook.svg"

import ModalSettingsLoading from './ModalSettingsLoading';
import ModalLoading from './ModalLoading';
import SimpleAlert from './SimpleAlert';

const AppCampaigns = () => {
    const navigate = useNavigate();
    const { lng } = useParams();
    const { t } = useTranslation()

    const [isCampaignsSettingModal, setIsCampaignsSettingModal] = useState(false)
    const [shop, setShop] = useState([]);
    const [country, setCountry] = useState('cs');
    const [saveSuccessful, setSaveSuccessful] = useState(false)
    const {
        userData,
        uuid,
        setUserData,
        pk,
        setPk,
        createCampaignObject,
        setcreateCampaignObject,
        campaignAssetsObject,
        setCampaignAssetsObject,
        selectedShop,
        isPmaxActive,
        setIsPmaxActive,
        isCampaignCreated,
        setIsCampaignCreated,
        hasItems,
        setHasItems,
        isCampaignDeactivated,
        setIsCampaignDeactivated,
        isUserDataLoading,
        setIsUserDataLoading,
        selectedImages,
        setSelectedImages,
        selectedLogos,
        setLogos,
        headline,
        setHeadline,
        longHeadline,
        setLongHeadline,
        shortDescPart1,
        setShortDescPart1,
        shortDescPart2,
        setShortDescPart2,
        descs,
        setDescs,
        deleteAll,
        campaignId,
        setAppContentType,
        showPNOWarning,
        showAlert,
        closeAlert,
        isAlertVisible,
        alertMessage,
        budget,
        pno
    } = useContext(AppContext);

    // useUserDetails(setShop, setCountry, "campaigns");
    useUserAppDetails()


    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            if (userData.shops && userData.shops.length > 0) {
                setcreateCampaignObject((prepState) => ({
                    ...prepState,
                    shop_id: uuid,
                    budget: budget, // na úrovni nastavení přepsat
                    roas: pno === 0 ? 0 : Number((100 / pno).toFixed(2)),
                    campaign_type: "google-pmax"
                }));
            } else {
                navigate(`/${lng}/app/register-shop/`);
            }
        }
    }, [userData, selectedShop]);

    useEffect(() => {
        setIsCampaignCreated(false);
    }, [selectedShop]);

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            if (Object.keys(userData?.shops).length > 0) {
                if (selectedShop) {
                    console.log("selected shop", selectedShop)
                    if (Object.keys(selectedShop?.campaign).length > 0) {
                        setIsCampaignCreated(true);

                        let pMaxCampaign = selectedShop.campaign.find(oneCampaign => oneCampaign?.campaign_type === "google-pmax");

                        if (pMaxCampaign) {
                            if (pMaxCampaign?.campaign_status === "deactived") {
                                setIsCampaignDeactivated(true);
                            }

                            if (Object.keys(pMaxCampaign?.addsets).length > 0 && pMaxCampaign.addsets[0].has_items) {
                                setHasItems(true)
                            } else {
                                setHasItems(false);
                            }

                            switch (pMaxCampaign.campaign_status) {
                                case "paused": {
                                    setIsPmaxActive(false);
                                    break;
                                }
                                case "active": {
                                    setIsPmaxActive(true);
                                    break;
                                }
                                default: {
                                    setIsPmaxActive(false);
                                    console.log("Unknown campaign status:", pMaxCampaign.campaign_status);
                                    break;
                                }
                            }
                        }

                        // Naplnění objektu pro assets
                        if (Object.keys(campaignAssetsObject).length === 0 && Object.keys(pMaxCampaign?.addsets).length > 0) {
                            setCampaignAssetsObject({
                                shop_id: selectedShop.uuid,
                                asset_id: pMaxCampaign.addsets[0].asset_id,
                                campaign_id: pMaxCampaign.campaign_id,
                                items: [
                                    {
                                        name: selectedShop.name,
                                        type: "BUSINESS_NAME"
                                    }
                                ]
                            });
                        }
                    } else {
                        setIsCampaignCreated(false);
                        setIsPmaxActive(false);
                    }
                }
            }
        }
    }, [userData, uuid, selectedShop]);


    const handleModal = () => {
        setIsCampaignsSettingModal(!isCampaignsSettingModal);
    };

    const onCreateCampaign = async () => {
        try {
            setIsUserDataLoading(true)
            const response = await axiosInstance.post("campaign/create/", createCampaignObject);
            setIsCampaignCreated(true);
            setIsCampaignDeactivated(true)
            handleModal();
            setIsUserDataLoading(false)
            deleteAll()
            !hasItems && setHeadline(["", "", ""])


            try {
                const responseUserData = await axiosInstance.get('user/detail/');

                if (selectedShop) {
                    const shopForObject = responseUserData.data.shops.find(oneShop => oneShop.uuid === selectedShop.uuid);
                    setCampaignAssetsObject({
                        shop_id: shopForObject.uuid,
                        asset_id: shopForObject.campaign[0].addsets[0].asset_id,
                        campaign_id: shopForObject.campaign[0].campaign_id,
                        items: [
                            {
                                name: shopForObject.name,
                                type: "BUSINESS_NAME"
                            }
                        ]
                    });
                } else {
                    const shopForObject = responseUserData.data.shops[0];
                    setCampaignAssetsObject({
                        shop_id: shopForObject.uuid,
                        asset_id: shopForObject.campaign[0].addsets[0].asset_id,
                        campaign_id: shopForObject.campaign[0].campaign_id,
                        items: [
                            {
                                name: shopForObject.name,
                                type: "BUSINESS_NAME"
                            }
                        ]
                    });
                }
            } catch (error) {
                setIsUserDataLoading(false)
                showAlert(t("appCampaigns.errorMessages.createCampaignErrorMsg"), "danger")
                console.log(error.response);
            }

        } catch (error) {
            setIsUserDataLoading(false)
            showAlert(t("appCampaigns.errorMessages.createCampaignErrorMsg"), "danger")
            if (error.response.data.error.includes("FINAL_URL_SHOPPING_MERCHANT_HOME_PAGE_URL_DOMAINS_DIFFER")) {
                showAlert("appCampaigns.errorMessages.accountsNotMatchErrorMsg", "danger")
            } else {
                console.error(error.response.data.error);
            }
        }
    };

    const onPauseCampaign = async () => {
        try {
            let objectForPause = {
                campaign_status: isPmaxActive ? "paused" : "active"
            }

            const response = await axiosInstance.patch(`campaign/${campaignAssetsObject.campaign_id}/update/`, objectForPause)
            console.log(response.data)

            if (objectForPause.campaign_status === "paused") {
                showAlert(t("appCampaigns.infoMessages.setToPausedInfoMsg"))
            } else if (objectForPause.campaign_status === "active") {
                showAlert(t("appCampaigns.infoMessages.setToActiveInfoMsg"))
            }
        } catch (error) {
            showAlert(t("appCampaigns.errorMessages.changeCampaignStatusErrorMsg", "danger"))
            setIsPmaxActive(!isPmaxActive)
            console.log(error.response.data)
        }
    }

    const onRetrieveAssets = async () => {
        try {
            deleteAll();
            setIsUserDataLoading(true);

            const response = await axiosInstance.get(`campaign/${campaignAssetsObject.campaign_id}/assets/${campaignAssetsObject.asset_id}/detail/`);
            setIsUserDataLoading(false);
            const { image_assets, text_assets } = response.data;

            const loadedHeadlines = [];
            const loadedLongHeadlines = [];
            const descriptions = []; // Pole pro popisy

            text_assets.forEach((oneText) => {
                if (oneText.type === "HEADLINE" && oneText.name) {
                    loadedHeadlines.push(oneText.name);
                }

                if (oneText.type === "LONG_HEADLINE") {
                    loadedLongHeadlines.push(oneText.name);
                }

                if (oneText.type === "DESCRIPTION") {
                    descriptions.push(oneText.name);
                }
            });

            // Nastavení nadpisů - pokud není dostatek nadpisů, doplní se prázdná pole
            setHeadline(loadedHeadlines.length > 0 ? loadedHeadlines : ["", "", ""]);

            // Nastavení dlouhých nadpisů
            setLongHeadline(loadedLongHeadlines.length > 0 ? loadedLongHeadlines : ["", ""]);

            // Nastavení popisů
            setDescs(descriptions.length > 0 ? descriptions : ["", ""]);

            // Zpracování obrázků a log, pokud jsou v datech
            image_assets.forEach((oneImage) => {
                const imageBase64 = `${oneImage.data}`;
                const img = new Image();

                img.src = imageBase64;

                img.onload = () => {
                    if (oneImage.type === "LOGO" || oneImage.type === "LANDSCAPE_LOGO") {
                        const isSquare = img.width === img.height;

                        setLogos(prevState => ([
                            ...(Array.isArray(prevState) ? prevState : []),
                            {
                                base64: imageBase64,
                                name: "",
                                id: oneImage.asset_item_id,
                                shape: isSquare ? "square" : "rect"  // Rozlišení mezi čtvercovým a širokým logem
                            }
                        ]));
                    }

                    if (oneImage.type === "MARKETING_IMAGE") {
                        setSelectedImages(prevState => ([
                            ...prevState,
                            {
                                base64: `${oneImage.data}`,
                                name: "",
                                id: oneImage.asset_item_id,
                                shape: "rect"
                            }
                        ]));
                    }

                    if (oneImage.type === "SQUARE_MARKETING_IMAGE") {
                        setSelectedImages(prevState => ([
                            ...prevState,
                            {
                                base64: `${oneImage.data}`,
                                name: "",
                                id: oneImage.asset_item_id,
                                shape: "square"
                            }
                        ]));
                    }
                };
            });
        } catch (error) {
            showAlert(t("appCampaigns.errorMessages.uploadAssetsErrorMsg"));
            console.error("Error retrieving assets:", error);
            setIsUserDataLoading(false);
        }
    };

    return (
        <>
            {isUserDataLoading && <ModalLoading />}
            <article className="app-content">
                <h1>{t("appCampaigns.campaigns")}</h1>

                <div className="item">
                    <span className="item__ico">
                        <img src={loginGoogle} alt="" />
                    </span>
                    <span className="item__title">Performance max</span>
                    {isCampaignCreated ?
                        <span className="item__control">
                            <a href="#" onClick={() => {
                                handleModal();
                                hasItems ? onRetrieveAssets() : setHeadline(["", "", ""]);
                            }} className="js-modal-settings">

                                <span className="icon icon--settings">
                                    <svg className="icon__svg">
                                        <use xlinkHref="/img/icons.svg#settings" x="0" y="0" width="100%" height="100%"></use>
                                    </svg>
                                </span>
                                {t("appCampaigns.setting")}
                            </a>
                            {
                                isCampaignDeactivated ?
                                    (
                                        <label className="switcher switcher--service">
                                            <input
                                                type="checkbox"
                                                name="checkbox-inactive"
                                                disabled
                                                onClick={showAlert}
                                            />
                                            <span className="switcher__label is-unchecked" onClick={showAlert}
                                            >{t("appCampaigns.inactive")}</span>
                                            <span className="switcher__back"></span>
                                        </label>
                                    )
                                    :
                                    isPmaxActive ? (
                                        hasItems ? (
                                            <label className="switcher switcher--service">
                                                <input
                                                    defaultChecked
                                                    type="checkbox"
                                                    name="checkbox-active"
                                                    onClick={() => setIsPmaxActive(!isPmaxActive)}
                                                    onChange={() => onPauseCampaign()}
                                                />
                                                <span className="switcher__label is-checked">{t("appCampaigns.active")}</span>
                                                <span className="switcher__back"></span>
                                            </label>
                                        ) : (
                                            <label className="switcher switcher--service">
                                                <input
                                                    type="checkbox"
                                                    name="checkbox-inactive"
                                                    disabled
                                                    checked={hasItems}
                                                    onClick={showAlert}
                                                />
                                                <span className="switcher__label is-unchecked" onClick={showAlert}
                                                >{t("appCampaigns.inactive")}</span>
                                                <span className="switcher__back"></span>
                                            </label>
                                        )
                                    ) : (
                                        <label className="switcher switcher--disabled">
                                            <input
                                                type="checkbox"
                                                name="checkbox-inactive"
                                                disabled={!hasItems}
                                                onClick={() => hasItems && setIsPmaxActive(!isPmaxActive)}
                                                onChange={() => onPauseCampaign()}
                                            />
                                            <span className="switcher__label is-unchecked">{t("appCampaigns.paused")}</span>
                                            <span className="switcher__back"></span>
                                        </label>
                                    )}
                        </span>
                        :
                        <input type="submit" value="Vytvořit kampaň" onClick={() => !showPNOWarning ? onCreateCampaign() : navigate(`/${lng}/app/settings/`, showAlert(t("appCampaigns.infoMessages.redirectInfoMsg")))
                        } className="btn"></input>
                    }
                </div>
            </article>
            <ModalSettingsLoading isCampaignsSettingModal={isCampaignsSettingModal} handleModal={handleModal} setSaveSuccessful={setSaveSuccessful} />
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    type={alertMessage.type}
                    onClose={closeAlert} />
            )}
        </>
    );
};

export default AppCampaigns;