import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../context/AppContext';
import axiosInstance from './AxiosConfig';

import successIcon from '../img/icons/success.svg';
import dangerIcon from '../img/icons/danger.svg';

import firstImg from "../img/credit/1.png";
import secondImg from "../img/credit/2.png";
import thirdImg from "../img/credit/3.png";
import fourthImg from "../img/credit/4.png";

import ImageDialog from './ImageDialog';

import SimpleAlert from './SimpleAlert';

import ModalNoCredit from './ModalNoCredit';

import CreditStyles from '../css/CreditStyles.module.css';


const AppCredit = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        setIsUserDataLoading,
        setAppContentType,
        setActiveItem,
        uuid,
        alertMessage,
        isAlertVisible,
        showAlert,
        closeAlert,
        isBilling
    } = useContext(AppContext);

    const [accountStatus, setAccountStatus] = useState('inactive'); // Možnosti: 'inactive', 'active'
    const [selectedImage, setSelectedImage] = useState(null); // Uložení vybraného obrázku
    const [isDialogOpen, setIsDialogOpen] = useState(false); // Stav modalu

    const onRedirect = (where) => {
        window.scrollTo(0, 0);
        navigate(`/app/${where}/`);
        setActiveItem(where);
        setAppContentType(where);
    };

    const openImageDialog = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsDialogOpen(true);
    };

    const closeImageDialog = () => {
        setIsDialogOpen(false);
        setSelectedImage(null);
    };

    const checkBilling = async (e) => {
        e.target.blur()
        setIsUserDataLoading(true)
        try {
            const checkBillingResponse = await axiosInstance.post(`shop/billing/${uuid}/`, {
                source_type: "google-ads"
            })

            console.log("Check billing response: ", checkBillingResponse)
            if (checkBillingResponse.data[0]?.billingSetup?.status?.toLowerCase() === "approved") {
                showAlert(t("appCredit.infoMessages.billingApprovedInfoMsg"), "info")
            } else {
                showAlert(t("appCredit.errorMessages.billingNotApprovedErrorMsg"), "danger")
            }
        } catch (error) {
            console.log("Check billing error: ", error)
            showAlert(t("appCredit.errorMessages.billingNotApprovedErrorMsg"), "danger")
        } finally {
            setIsUserDataLoading(false)
        }
    }

    return (
        <>
            {
                isAlertVisible && (
                    <SimpleAlert
                        message={alertMessage.text}
                        onClose={closeAlert}
                        type={alertMessage.type}
                    />
                )
            }
            {
                !isBilling &&
                <ModalNoCredit />
            }
            <article className="app-content">
                <div className={CreditStyles.headline_and_btn_box}>
                    <h1>
                        {t('appCredit.title')}
                    </h1>
                    <p style={{ textAlign: "right" }}>
                        <button
                            className="btn"
                            onClick={(e) => checkBilling(e)}>
                            {t('appCredit.controlCreditBtn')}
                        </button>
                    </p>
                </div>

                <section className="credit-info">
                    <p>{t('appCredit.creditInfo.description')}</p>
                    <p>
                        <Trans i18nKey='appCredit.creditInfo.tutor' components={[<strong></strong>]} />
                    </p>
                </section>

                <section className="how-to">
                    <h2>{t('appCredit.howTo.title')}</h2>
                    <p>{t('appCredit.howTo.description')}</p>
                </section>

                <section className="billing">
                    <h2>{t('appCredit.billing.title')}</h2>
                    <ol>
                        <li>{t('appCredit.billing.steps.0')}</li>
                        <img
                            src={firstImg}
                            alt="ads-tutor"
                            className={CreditStyles['img-full-width']}
                            onClick={() => openImageDialog(firstImg)}
                        />
                        <li>{t('appCredit.billing.steps.1')}</li>
                        <li>{t('appCredit.billing.steps.2')}</li>
                        <img
                            src={secondImg}
                            alt="ads-tutor"
                            className={CreditStyles['img-half-width']}
                            onClick={() => openImageDialog(secondImg)}
                        />
                        <li>{t('appCredit.billing.steps.3')}</li>
                        <img
                            src={thirdImg}
                            alt="ads-tutor"
                            className={CreditStyles['img-full-width']}
                            onClick={() => openImageDialog(thirdImg)}
                        />
                    </ol>
                    <p>{t('appCredit.billing.advice')}</p>
                </section>

                <section className="automatic-payments">
                    <h2>{t('appCredit.automaticPayments.title')}</h2>
                    <p>{t('appCredit.automaticPayments.description')}</p>
                    <p>{t('appCredit.automaticPayments.currentLimit')}</p>
                    <img
                        src={fourthImg}
                        alt="ads-tutor"
                        className={CreditStyles['img-full-width']}
                        onClick={() => openImageDialog(fourthImg)}
                    />
                </section>
                <p style={{ marginTop: 50, textAlign: "right" }}>
                    <button
                        className="btn"
                        onClick={(e) => checkBilling(e)}>
                        {t('appCredit.controlCreditBtn')}
                    </button>
                </p>
                {isDialogOpen && (
                    <ImageDialog
                        imageSrc={selectedImage}
                        onClose={closeImageDialog}
                    />
                )}
            </article>
        </>
    );
};

export default AppCredit;
