import React, { useEffect, useState } from 'react';
import styles from '../css/ModalLoading.module.css'; // Import CSS modulu
import Loading from './Loading';

const ModalLoading = ({ message }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    // Vytvoříme interval pro animaci teček
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev.length === 3) {
          return ''; // Reset teček na prázdno
        } else {
          return prev + '.'; // Přidání jedné tečky
        }
      });
    }, 350); // Změna teček každých 500 ms

    return () => clearInterval(interval); // Vyčistíme interval při odchodu
  }, []);

  useEffect(() => {
    // Když je modal zobrazen, nastaví overflow: hidden
    document.body.style.overflow = 'hidden';

    // Když se modal skryje, odstraní overflow: hidden
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className={styles.moduleLoadingOverlay}>
      <div className={styles.moduleLoading}>
        <Loading />
        <h3 style={{ color: 'white', whiteSpace: 'nowrap' }}>
          {message ? message : 'Pracuju na tom'}
          <span style={{ display: 'inline-block', width: '1ch' }}>
            {dots}
          </span>
        </h3>
      </div>
    </div>
  );
};

export default ModalLoading;
