import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ModalNoCreditStyle from "../css/ModalNoCredit.module.css"

import { AppContext } from '../context/AppContext'

const ModalNoCredit = () => {
    const { t } = useTranslation()

    const {
        setIsBilling
    } = useContext(AppContext)

    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = "auto"
        }
    }, [])

    const onClose = () => {
        setIsBilling(true)
    }

    return (
        <div className={ModalNoCreditStyle.container}>
            <button
                className={ModalNoCreditStyle.close_btn}
                onClick={onClose}
            >
                &times;
            </button>
            <div className={ModalNoCreditStyle.modal_box}>
                <div className={ModalNoCreditStyle.textBox}>
                    <h2 className={ModalNoCreditStyle.text}>{t("modalNoCredit.heading")}</h2>
                    <p className={ModalNoCreditStyle.text}>{t("modalNoCredit.noBilling")}</p>
                    <p className={ModalNoCreditStyle.text}>{t("modalNoCredit.infoAboutTutor")}</p>
                </div>
                <div className={ModalNoCreditStyle.btn_box}>
                    <input
                        type="button"
                        className={`btn ${ModalNoCreditStyle.btn}`}
                        value={t('modalNoCredit.findOutMore')}
                        onClick={onClose}
                    />
                </div>
            </div>
        </div>
    )
}

export default ModalNoCredit