import axios from 'axios';
import Cookies from 'js-cookie';

// const refreshAccessToken = async (refreshToken) => {
//   try {
//     const response = await fetch('https://permoniq.com/api/token/refresh/', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ refresh: refreshToken }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to refresh access token');
//     }

//     const data = await response.json();
//     Cookies.set('aTkn_0yD9K', data.access, { secure: true, sameSite: 'None', expires: 1 });
//     return data.access;
//   } catch (error) {
//     console.error('Token refresh failed:', error);
//     return null;
//   }
// };

const axiosInstance = axios.create({
  baseURL: 'https://permoniq.com/api/', // Base URL of your API
  headers: {
    'Content-Type': 'application/json',
  },
});

// const checkLoginStatus = async () => {
//   const accessToken = Cookies.get('aTkn_0yD9K');
//   const refreshToken = Cookies.get('rTkn_4zF7P');

//   if (accessToken && refreshToken) {
//     sessionStorage.setItem("isLogged", true)
//     return true;
//   } else {
//     console.log("is not logged")
//   }

//   if (refreshToken) {
//     const newAccessToken = await refreshAccessToken(refreshToken);
//     if (newAccessToken) {
//       sessionStorage.setItem("isLogged", true)
//       return true;
//     }
//   }

//   sessionStorage.setItem("isLogged", false)
//   return false;
// };

// checkLoginStatus()

// Add a request interceptor to include the token in the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('aTkn_0yD9K');
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      const lng = Cookies.get("i18next") || "cs";
      window.location.href = `/${lng}/login/`;

      // Použití setTimeout pro zpoždění výpisu chyby, aby došlo k přesměrování nejdříve
      setTimeout(() => {
        if (lng === "cs") {
          alert("Došlo k chybě, zkuste se znovu přihlásit!");
        } else if (lng === "en") {
          alert("An error occurred, try to login again!");
        } else if (lng === "pl") {
          alert("Wystąpił błąd, spróbuj ponownie się zalogować!");
        } else if (lng === "hu") {
          alert("Hiba történt, próbáljon újra bejelentkezni!");
        } else if (lng === "sk") {
          alert("Došlo k chybe, skúste sa znova prihlásiť!");
        } else if (lng === "ro") {
          alert("A apărut o eroare, încercați să vă conectați din nou!");
        } else {
          alert("An error occurred, try to login again!");
        }
      }, 500); // Přidání malého zpoždění pro vypsání alertu po přesměrování
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default axiosInstance;
