import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { LineChart, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import Calendar from 'react-calendar';
import { ReactSVG } from 'react-svg';

import 'react-calendar/dist/Calendar.css';

import useUserAppDetails from '../hooks/useUserAppDetails';

import axiosInstance from './AxiosConfig';

import { AppContext } from '../context/AppContext';

import styleForInfoModal from "../css/ModalInfo.module.css"

import converse from "../img/icons/converse.svg";
import grow from "../img/icons/grow.svg";
import chart from "../img/icons/chart.svg";
import money from "../img/icons/money.svg";
import wallet from "../img/icons/wallet.svg";
import user from "../img/icons/user.svg";
import click from "../img/icons/click.svg";
import basket from "../img/icons/basket.svg";
import target from "../img/icons/target.svg"

import ModalInfo from './ModalInfo';

const AppStatistics = () => {
    const { t } = useTranslation()

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [dateRange, setDateRange] = useState([
        new Date(new Date().setDate(new Date().getDate() - 30)),
        new Date() // End date: today
    ]);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dailyStats, setDailyStats] = useState([])

    const {
        userData,
        uuid,
        selectedShop,
        pno,
        toLocale,
        setStatsCurrency,
        getCurrency
    } = useContext(AppContext)

    useUserAppDetails()

    const [totalExpenses, setTotalExpenses] = useState({
        value: 0,
        currency: getCurrency(selectedShop.currency),
        type: "Money"
    });

    const [cos, setCos] = useState({
        value: 0,
        type: "Percent"
    });

    const [generatedRevenue, setGeneratedRevenue] = useState({
        value: 0,
        currency: getCurrency(selectedShop.currency),
        type: "Money"
    });

    const [assistedRevenue, setAssistedRevenue] = useState({
        value: 0,
        currency: getCurrency(selectedShop.currency),
        type: "Money"
    });

    const [visitorCount, setVisitorCount] = useState({
        value: 0,
        type: "Number"
    });

    const [clickCount, setClickCount] = useState({
        value: 0,
        type: "Number"
    });

    const [orderCount, setOrderCount] = useState({
        value: 0,
        type: "Number"
    });

    const [conversionRatio, setConversionRatio] = useState({
        value: 0,
        type: "Percent"
    });


    useEffect(() => {
        retrieveGraphData();
        retrieveDailyStats();
    }, [uuid, dateRange]);

    const toggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const onChange = (range) => {
        setDateRange(range);
        console.log(range)
        setIsCalendarOpen(false);
    };

    const formatDate = (date) => {
        return date.toLocaleDateString('cs-CZ');
    };

    const retrieveGraphData = async () => {
        if (uuid) {
            try {
                // const campaignType = selectedShop?.campaign?.[0]?.campaign_type ?? userData.shops?.[0]?.campaign?.[0]?.campaign_type;


                const retrieveGraphDataResponse = await axiosInstance.post(`shop/stats/summary/${uuid}/`, {
                    start_date: new Date(dateRange[0].getTime() - (dateRange[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0],
                    end_date: dateRange[1].toISOString().split('T')[0],
                    // campaign_type: campaignType
                });

                console.log("Stats data", retrieveGraphDataResponse.data);
                setStatsCurrency(retrieveGraphDataResponse.data.generatedRevenue.currency)

                setTotalExpenses({
                    value: retrieveGraphDataResponse.data.totalExpenses.value,
                    currency: getCurrency(selectedShop.currency),
                    type: retrieveGraphDataResponse.data.totalExpenses.type
                });

                setCos({
                    value: retrieveGraphDataResponse.data.cos.value,
                    type: retrieveGraphDataResponse.data.cos.type
                });

                setGeneratedRevenue({
                    value: retrieveGraphDataResponse.data.generatedRevenue.value,
                    currency: getCurrency(selectedShop.currency),
                    type: retrieveGraphDataResponse.data.generatedRevenue.type
                });

                setAssistedRevenue({
                    value: retrieveGraphDataResponse.data.assistedRevenue.value,
                    currency: getCurrency(selectedShop.currency),
                    type: retrieveGraphDataResponse.data.assistedRevenue.type
                });

                setVisitorCount({
                    value: retrieveGraphDataResponse.data.visitorCount.value,
                    type: retrieveGraphDataResponse.data.visitorCount.type
                });

                setClickCount({
                    value: retrieveGraphDataResponse.data.clickCount.value,
                    type: retrieveGraphDataResponse.data.clickCount.type
                });

                setOrderCount({
                    value: retrieveGraphDataResponse.data.orderCount.value,
                    type: retrieveGraphDataResponse.data.orderCount.type
                });

                setConversionRatio({
                    value: retrieveGraphDataResponse.data.conversionRatio.value,
                    type: retrieveGraphDataResponse.data.conversionRatio.type
                });

            } catch (error) {
                console.log("Stats data error", error);
            }
        }
    };

    const retrieveDailyStats = async () => {
        if (uuid) {
            try {
                // const campaignType = selectedShop?.campaign?.[0]?.campaign_type ?? userData.shops?.[0]?.campaign?.[0]?.campaign_type;

                const retrieveDailyStatsResponse = await axiosInstance.post(`shop/stats/daily/${uuid}/`, {
                    start_date: new Date(dateRange[0].getTime() - (dateRange[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0],
                    end_date: dateRange[1].toISOString().split('T')[0],
                    // campaign_type: campaignType
                });

                const updatedStats = retrieveDailyStatsResponse.data.map(item => ({
                    ...item,
                    conversion_rate: Number(((item.total_conversions / item.total_visitors) * 100).toFixed(2))
                }));

                console.log("Daily stats with conversionRate: ", updatedStats);
                setDailyStats(updatedStats);
            } catch (error) {
                console.log("Daily stats error: ", error);
            }
        }
    };


    const downloadCSV = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const uuid = selectedShop?.uuid ?? userData.shops[0]?.uuid;
            // const campaignType = selectedShop?.campaign?.[0]?.campaign_type ?? userData.shops?.[0]?.campaign?.[0]?.campaign_type;

            const downloadCSVResponse = await axiosInstance.post(`shop/stats/daily/${uuid}/?download=true`, {
                start_date: new Date(dateRange[0].getTime() - (dateRange[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0],
                end_date: dateRange[1].toISOString().split('T')[0],
                // campaign_type: campaignType,
            }, { responseType: 'json' });

            const jsonData = downloadCSVResponse.data;

            const csvData = jsonData.map(row => ({
                date: row.date,
                total_clicks: row.total_clicks,
                total_cost: row.total_cost,
                total_visitors: row.total_visitors,
                total_assisted_conversions: row.total_assisted_conversions,
                total_conversions: row.total_conversions,
                total_assisted_conversion_value: row.total_assisted_conversion_value,
                total_conversion_value: row.total_conversion_value
            }));

            const headers = Object.keys(csvData[0]).join(',');
            const rows = csvData.map(row => Object.values(row).join(',')).join('\n');
            const csvContent = `${headers}\n${rows}`;

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'daily_stats.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            console.log("Daily stats download: ", downloadCSVResponse);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleModal = (targetId) => {
        setIsModalOpen(!isModalOpen);

        if (!isModalOpen && targetId) {
            setTimeout(() => {
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                    // Posun na zvolený element
                    targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
                    // Přidá CSS třídu s pulzující animací
                    targetElement.classList.add("pulse__border__no__outline");

                    // Po 3 sekundách třídu odebere
                    setTimeout(() => {
                        targetElement.classList.remove("pulse__border__no__outline");
                    }, 3000); // 3000 ms = 3 sekundy
                }
            }, 100); // Zpoždění pro načtení modalu
        }
    };

    return (
        <article className="app-content">
            <h1>{t("appStatistics.statistics")}</h1>

            <div className="app-content__row">
                <div className="app-content__col">
                    {/* <p>
                        <label htmlFor="campaign-type">Typy kampaní</label>
                        <select name="campaign-type" id="campaign-type">
                            <option value="Všechny kampaně">Google P-Max</option>
                        </select>
                    </p> */}
                </div>

                <div className="app-content__col">
                    <div className="my-calendar-dropdown">
                        <p>
                            <label htmlFor="date-range">{t("appStatistics.chooseRange")}</label>
                            <input
                                type="text"
                                id="date-range"
                                value={`${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`}
                                readOnly
                                onClick={toggleCalendar}
                            />
                        </p>
                        {isCalendarOpen && (
                            <div className="calendar-container">
                                <Calendar
                                    onChange={onChange}
                                    selectRange
                                    value={dateRange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <p>
                {t("appStatistics.export")}
                <button className="export" onClick={downloadCSV} disabled={isLoading || dailyStats.length === 0}>
                    {isLoading ? 'Stahování...' : 'CSV'}
                </button>
            </p>

            <div className="box">
                <h2>{t("appStatistics.reportForYou")}</h2>
                <p>{t(t("appStatistics.statsPerTimeInfo"))}</p>
            </div>

            <div className="app-content__row">
                <div className="info">
                    <span className={`pack pack--1 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#2768B5" }} onClick={() => setTimeout(() => {
                        toggleModal("total__expenses__explain")
                    }, 250)}>
                        <span className="icon icon--grow">
                            <ReactSVG src={money} />
                        </span>
                    </span>
                    <span className="info__title">{t("appStatistics.totalExpenses")}</span>
                    <span className={`info__value ${generatedRevenue.value < 0 ? 'is-negative' : 'is-positive'}`}>{toLocale(totalExpenses.value)} {totalExpenses.currency}</span>
                </div>

                <div className="info">
                    <span className={`pack pack--2 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#E4B200" }} onClick={() => setTimeout(() => {
                        toggleModal("pno__explain")
                    }, 250)}>
                        <span className="icon icon--chart">
                            <ReactSVG src={target} />
                        </span>
                    </span>
                    <span className="info__title">PNO</span>
                    <span className="info__value">
                        <span
                            // style={{ color: cos.value > pno ? 'red' : generatedRevenue.value < 0 ? 'red' : 'black' }}
                            title={`PNO`}
                        >
                            {(cos.value).toFixed(2)} %
                        </span>
                        &nbsp;/&nbsp;
                        <span
                            title={t("appStatistics.targetPNO")}
                        >
                            {pno} %
                        </span>

                    </span>
                </div>
            </div>

            <div className="app-content__row">
                <div className="info">
                    <span className={`pack pack--3 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#D455CF" }} onClick={() => setTimeout(() => {
                        toggleModal("generated__revenue__explain")
                    }, 250)}>
                        <span className="icon icon--money">
                            <ReactSVG src={grow} />
                        </span>
                    </span>
                    <span className="info__title">{t("appStatistics.generatedRevenue")}</span>
                    <span className={`info__value ${generatedRevenue.value < 0 ? 'is-negative' : 'is-positive'}`}>{toLocale(generatedRevenue.value)} {generatedRevenue.currency}</span>
                </div>

                <div className="info">
                    <span className={`pack pack--4 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#12B3F8" }} onClick={() => setTimeout(() => {
                        toggleModal("assisted__revenue__explain")
                    }, 250)}>
                        <span className="icon icon--wallet">
                            <ReactSVG src={wallet} />
                        </span>
                    </span>
                    <span className="info__title">{t("appStatistics.assistedRevenue")}</span>
                    <span className="info__value">{toLocale(assistedRevenue.value)} {assistedRevenue.currency}</span>
                </div>
            </div>

            <div className="box">
                <div className="graph">
                    <ResponsiveContainer width="100%" height={300}>
                        {dailyStats.length > 0 ? (
                            <LineChart data={dailyStats}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" interval="equidistantPreserveStart" />
                                <YAxis tickFormatter={(value) => toLocale(value)} />
                                <Tooltip formatter={(value) => `${toLocale(value)} ${getCurrency(selectedShop.currency)}`} />
                                <Legend layout="horizontal" verticalAlign="top" align="center" height={50} />
                                <Line
                                    type="monotone"
                                    legendType='circle'
                                    name='Celkové výdaje'
                                    dataKey="total_cost"
                                    stroke="#2768B5"
                                    strokeWidth={4}
                                />
                                <Line
                                    type="monotone"
                                    legendType='circle'
                                    name='Generovaný obrat'
                                    dataKey="total_conversion_value"
                                    stroke="#D455CF"
                                    strokeWidth={4}
                                />
                            </LineChart>
                        ) : (
                            <div style={{ textAlign: 'center', lineHeight: '300px', fontSize: '18px', color: '#999' }}>
                                No data
                            </div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>


            <div className="app-content__row">
                <div className="info">
                    <span className={`pack pack--5 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#00ACB7" }} onClick={() => setTimeout(() => {
                        toggleModal("visitor__count__expolain")
                    }, 250)}>
                        <span className="icon icon--user">
                            <ReactSVG src={user} style={{ fill: "white" }} />
                        </span>
                    </span>
                    <span className="info__title">{t("appStatistics.visitorCount")}</span>
                    <span className={`info__value ${visitorCount.value < 0 ? 'is-negative' : 'is-positive'}`}>{toLocale(visitorCount.value)}</span>
                </div>

                <div className="info">
                    <span className={`pack pack--6 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#ED7200" }} onClick={() => setTimeout(() => {
                        toggleModal("click__count__explain")
                    }, 250)}>
                        <span className="icon icon--click">
                            <ReactSVG src={click} />
                        </span>
                    </span>
                    <span className="info__title">{t("appStatistics.clickCount")}</span>
                    <span className={`info__value ${clickCount.value < 0 ? 'is-negative' : 'is-positive'}`}>{toLocale(clickCount.value)}</span>
                </div>
            </div>

            <div className="app-content__row">
                <div className="info">
                    <span className={`pack pack--7 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#8053C9" }} onClick={() => setTimeout(() => {
                        toggleModal("order__count__explain")
                    }, 250)}>
                        <span className="icon icon--basket">
                            <ReactSVG src={basket} />
                        </span>
                    </span>
                    <span className="info__title">{t("appStatistics.orderCount")}</span>
                    <span className={`info__value ${orderCount.value < 0 ? 'is-negative' : 'is-positive'}`}>{toLocale(orderCount.value)}</span>
                </div>

                <div className="info">
                    <span className={`pack pack--8 ${styleForInfoModal.stats_icon}`} style={{ "--primary-hover-color": "#fff", "--secondary-hover-color": "#F889D2" }} onClick={() => setTimeout(() => {
                        toggleModal("conversion__ratio__explain")
                    }, 250)}>
                        <span className="icon icon--converse">
                            <img src={converse} alt="converse" />
                        </span>
                    </span>
                    <span className="info__title">{t("appStatistics.conversionRation")}</span>
                    <span className={`info__value ${conversionRatio.value < 0 ? 'is-negative' : 'is-positive'}`}>{(conversionRatio.value).toFixed(2)} %</span>
                </div>
            </div>

            <div className="box">
                <div className="graph">
                    <ResponsiveContainer width="100%" height={300}>
                        {dailyStats.length > 0 ? (
                            <ComposedChart data={dailyStats}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" interval="equidistantPreserveStart" />
                                <YAxis yAxisId="left" tickFormatter={(value) => toLocale(value)} />
                                <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => `${value}%`} />
                                <Tooltip />
                                <Legend layout="horizontal" verticalAlign="top" align="center" height={50} />
                                <Bar yAxisId="left" dataKey="total_visitors" fill="#00ACB7" name="Počet návštěvníků" legendType='circle' />
                                <Bar yAxisId="left" dataKey="total_conversions" fill="#8053C9" name="Počet objednávek" legendType='circle' />
                                <Line yAxisId="right" type="monotone" legendType='circle' name='Konverzní poměr' dataKey="conversion_rate" stroke="#D455CF" strokeWidth={2} dot={{ strokeWidth: 1 }} />
                            </ComposedChart>
                        ) : (
                            <div style={{ textAlign: 'center', lineHeight: '300px', fontSize: '18px', color: '#999' }}>
                                No data
                            </div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>


            <div className="box">
                <div className="graph">
                    <ResponsiveContainer width="100%" height={300}>
                        {dailyStats.length > 0 ? (
                            <LineChart data={dailyStats}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" interval="equidistantPreserveStart" />
                                <YAxis tickFormatter={(value) => toLocale(value)} />
                                <Tooltip />
                                <Legend layout="horizontal" verticalAlign="top" align="center" height={50} />
                                <Line type="monotone" dataKey="total_clicks" stroke="#ED7200" strokeWidth={4} name="Počet kliknutí" legendType='circle' />
                                {/* <Line type="monotone" dataKey="total_visitors" stroke="#00ACB7" strokeWidth={4} name="Počet návštěvníků" legendType='circle' /> */}
                            </LineChart>
                        ) : (
                            <div style={{ textAlign: 'center', lineHeight: '300px', fontSize: '18px', color: '#999' }}>
                                No data
                            </div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>


            <p className="link" onClick={toggleModal} style={{ cursor: "pointer" }}>{t("statisticsModal.modalLink")}</p>
            <ModalInfo isOpen={isModalOpen} toggleModal={toggleModal} t={t} />
        </article>
    );
};

export default AppStatistics;
