import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AppContext } from '../context/AppContext';

import Cookies from 'js-cookie';

import NavbarMain from './NavbarMain';
import NavbarLogin from './NavbarLogin';
import NavBarApp from './NavbarApp';

const Header = () => {
    const location = useLocation()
    const { pathname } = location

    const [isNotFound, setIsNotFound] = useState(false)

    const {
        setAppContentType,
        setActiveItem
    } = useContext(AppContext)

    // Nastavení header v případě, že je stránka 404
    useEffect(() => {
        Cookies.get("notfound") && setIsNotFound(true)
    }, [])

    
    
    const isLogin = pathname.includes("login")
    const isRegistrationAccount = pathname.includes("registration-account")
    const isShopOption = pathname.includes("shop-option")
    const isTermsOfService = pathname.includes("terms-of-service")
    const isPrivacyPolicy = pathname.includes("privacy-policy")
    const isGoogleTermsPage = pathname.includes("google-terms")
    const isPasswordRestore = pathname.includes("user-password-reset-confirm")
    
    // Registrace
    const isRegisterShop = pathname.includes("register-shop")
    const isCompanyDetails = pathname.includes("company-details")
    const isUploadProducts = pathname.includes("upload-products")
    const isConnectGoogle = pathname.includes("connect-google")
    const isInstallTrackingCode = pathname.includes("install-tracking-code")
    
    // Aplikace
    const isDashboard = pathname.includes("dashboard")
    const isStatistics = pathname.includes("statistics")
    const isCampaigns = pathname.includes("campaigns")
    const isCredit = pathname.includes("credit")
    const isSettings = pathname.includes("settings")
    const isHelp = pathname.includes("help")
    const isContactUs = pathname.includes("contact-us")

    // Blog
    const isBlog = pathname.includes("blog")
    
    
    useEffect(() => {
        if (isDashboard) {
            setActiveItem("dashboard");
            setAppContentType("dashboard");
        }
        if (isStatistics) {
            setActiveItem("statistics");
            setAppContentType("statistics");
        }
        if (isCampaigns) {
            setActiveItem("campaigns");
            setAppContentType("campaigns");
        }
        if (isCredit) {
            setActiveItem("credit");
            setAppContentType("credit");
        }
        if (isSettings) {
            setActiveItem("settings");
            setAppContentType("settings");
        }
        if (isHelp) {
            setActiveItem("help");
            setAppContentType("help");
        }
        if (isContactUs) {
            setActiveItem("contact-us");
            setAppContentType("contact-us");
        }
    }, [isDashboard, isStatistics, isCampaigns, isCredit, isSettings, isHelp, isContactUs]);
    
    return (
        <div className='header'>
            {isNotFound || isLogin || isShopOption || isRegistrationAccount || isRegisterShop || isCompanyDetails || isUploadProducts || isConnectGoogle || isInstallTrackingCode || isTermsOfService || isPrivacyPolicy || isGoogleTermsPage || isBlog || isPasswordRestore ? (
                <NavbarLogin />
            ) : isDashboard || isStatistics || isCampaigns || isCredit || isSettings || isHelp || isContactUs ? (
                <NavBarApp />
            ) : (
                <NavbarMain />
            )}
        </div>
    )
}

export default Header