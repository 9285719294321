import React from "react";
import { useTranslation } from "react-i18next";

const TermsOfServices = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-of-service">
      <h2>{t("termsOfService.title")}</h2>
      <p>{t("termsOfService.introduction")}</p>
      <p>{t("termsOfService.transition")}</p>
      <h3>{t("termsOfService.warranties_title")}</h3>
      <p>{t("termsOfService.warranties")}</p>
      <p>{t("termsOfService.disclaimer")}</p>
      <p>{t("termsOfService.privacy_update")}</p>
    </div>
  );
};

export default TermsOfServices;
